<template>
  <select class="custom-select" v-model="valueChild">
    <option value="">{{ noSelectLabel }}</option>
    <option v-for="item in list" :value="item.id" :key="item.id">{{
      item.name
    }}</option>
  </select>
</template>

<script>
import { AdminService } from "../AdminService.js";
import { mapState } from "vuex";

export default {
  props: {
    value: {
      default: 0
    },
    all: { default: false },
    noSelectLabel: { default: " ---  " }
  },
  data: () => ({
    list: []
  }),
  created() {
    AdminService.getAreas().then((res) => {
      this.list = res.filter((x) => x.enable);
    });
  },
  computed: {
    ...mapState({
      user: (s) => s.user
    }),
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    setDefault() {
      this.$emit("input", this.user.area_id);
    }
  }
};
</script>

<style></style>
