// default select array
<template>
  <Multiselect
    v-model="val"
    :options="options"
    placeholder="Selecciona Uno"
    selectLabel="Presiona enter para seleccionar"
    deselectLabel="Presiona enter para deseleccionar"
    :loading="isLoading"
    @search-change="find"
    :internal-search="false"
    @open="options.length != 0 || getList()"
  >
    <template slot="singleLabel" slot-scope="props">{{
      props.option.name
    }}</template>
    <template slot="option" slot-scope="props">{{
      props.option.name
    }}</template>
    <span slot="noOptions">Sin resultados</span>
    <span slot="noResult">No hay resultados</span>
  </Multiselect>
</template>

<script>
import { AdminService } from "../AdminService";
import { debounce } from "lodash";
// import { mapState } from "vuex";
import { Multiselect } from "vue-multiselect";

export default {
  components: {
    Multiselect
  },
  props: {
    value: {
      default: undefined
    },
    area_id: {
      default: undefined
    }
  },
  watch: {
    val(to) {
      if (to == null || to.id == undefined) {
        this.$emit("input", undefined);
      } else {
        this.$emit("input", to.id);
      }
    }
    // area_id() {
    //   this.getList();
    // }
  },
  computed: {
    //
  },
  data() {
    return {
      isLoading: false,
      val: undefined,
      options: []
    };
  },
  created() {
    // this.getList();
  },
  methods: {
    setValue(sourceId) {
      this.setValueFromId(sourceId);
    },
    reset() {
      this.options = [];
      this.val = null;
    },
    setValueFromId(source_id) {
      if (source_id) {
        this.isLoading = true;
        AdminService.getSource(source_id).then((res) => {
          this.isLoading = false;
          this.options = [res];
          this.val = res;
          this.$emit("input", source_id);
        });
      } else {
        this.val = {};
      }
    },
    setDefault() {
      // let area;
      AdminService.getAreas().then((list) => {
        for (let l of list) {
          // console.log(l);
          if (l.id == this.area_id) {
            this.setValueFromId(l.source_id);
            return;
          }
        }
      });
    },
    getList(search) {
      AdminService.getSources({
        area_id: this.area_id,
        search
      }).then((list) => {
        this.options = list.data;
        this.isLoading = false;
      });
    },
    find(search) {
      if (search) this.asyncFind(search, this);
    },
    asyncFind: debounce((search, vm) => {
      vm.isLoading = true;
      vm.getList(search);
    }, 350)
  }
};
</script>
<style></style>
