<template>
  <select
    class="custom-select form-select"
    :value="value"
    @input="$emit('input', $event.target.value)"
  >
    <option :value="undefined"></option>
    <option :value="1">BIENES</option>
    <option :value="2">SERVICIOS</option>
  </select>
</template>

<script>
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    value: {
      //
    },
    disabled: {
      default: false
    }
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style scoped></style>
